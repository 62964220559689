import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import App from './App.vue'
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import router from "./router";
import './assets/main.css'
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

import { initializeApp } from 'firebase/app';
import firebaseConfig from './firebaseConfig';
import { VueFire, VueFireAuth } from 'vuefire'

import VueGtag from "vue-gtag-next";

import '@/assets/styles.scss';
import '@/assets/tailwind.css';

export const app = createApp(App)

const themePreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}'
    },
    colorScheme: {
      light: {
        surface: {
          0: '#ffffff',
          50: '{zinc.50}',
          100: '{zinc.100}',
          200: '{zinc.200}',
          300: '{zinc.300}',
          400: '{zinc.400}',
          500: '{zinc.500}',
          600: '{zinc.600}',
          700: '{zinc.700}',
          800: '{zinc.800}',
          900: '{zinc.900}',
          950: '{zinc.950}'
        }
      },
      dark: {
        surface: {
          0: '#ffffff',
          50: '{slate.50}',
          100: '{slate.100}',
          200: '{slate.200}',
          300: '{slate.300}',
          400: '{slate.400}',
          500: '{slate.500}',
          600: '{slate.600}',
          700: '{slate.700}',
          800: '{slate.800}',
          900: '{slate.900}',
          950: '{slate.950}'
        }
      }
    }
  },
  components: {
    /* global settings */
    card: {
      colorScheme: {
        light: {
          root: {
            background: '{surface.0}',
            color: '{surface.700}'
          },
          subtitle: {
            color: '{surface.500}'
          }
        },
        dark: {
          root: {
            background: '{surface.900}',
            color: '{surface.0}'
          },
          subtitle: {
            color: '{surface.400}'
          }
        }
      }
    }
  }
});

app.use(PrimeVue, {
  theme: {
    preset: themePreset,
    options: {
      darkModeSelector: '.app-dark'
    }
  }
});
app.use(createPinia())
app.use(router)
app.use(ToastService);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);

app.use(VueGtag, {
  property: {
    id: "G-ZPK780HRLH",
  },
  appName: 'Bracket Champion',
  useDebugger: true
});

app.use(VueFire, {
  firebaseApp: initializeApp(firebaseConfig),
  modules: [
    VueFireAuth(),
  ],
})

router.isReady().then(() => {
  app.mount("#app");
})
