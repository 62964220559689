import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse, type AxiosError, type InternalAxiosRequestConfig } from "axios";
import type { ToastMessageOptions } from 'primevue/toast';
import { ToastSeverity } from '@primevue/core/api';
import { toaster } from './toaster'; 
import { useCurrentUser } from 'vuefire'

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> { }
}

enum StatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Credentials": false,
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  try {
    const user = useCurrentUser();
    if (!user.value) return config;
    const token = await user.value?.getIdToken();

    if (token != null && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const baseUrl = import.meta.env.VITE_BASE_URL ?? '';
    const inst = axios.create({
      baseURL: baseUrl,
      headers,
      withCredentials: false,
    });

    inst.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    inst.interceptors.response.use(
      ({ data }: AxiosResponse) => data,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );

    this.instance = inst;
    return inst;
  }

  private showToast = (message: ToastMessageOptions) => {
    const toast = toaster();
    toast.add(message);
}

  request<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.http.request(config);
  }

  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.http.get<T, T>(url, config);
  }

  post<T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.http.post<T, T>(url, data, config);
  }

  put<T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.http.put<T, T>(url, data, config);
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.http.delete<T, T>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error: any) {
    if (error === undefined) return;
    console.log(error);
    const { status } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        this.showToast({ detail: error.message?.data ?? error.message ?? error.statusText, life: 5000, severity: "error", summary: 'Oops!' });
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        this.showToast({ detail: error.message?.data ?? error.message ?? error.statusText, life: 5000, severity: "error", summary: 'Oops!' });
        break;
      }
      case StatusCode.Unauthorized: {
        this.showToast({ 
          detail: `You've been logged out due to inactivity.  You're being redirected to the login page...`, 
          life: 5000, 
          severity: "error", 
          summary: 'Logged out!' });
        // Handle Unauthorized
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        this.showToast({ detail: error.message?.data ?? error.message ?? error.statusText, life: 5000, severity: "error", summary: 'Oops!' });
        break;
      }
      case StatusCode.BadRequest: {
        // Handle BadRequest
        this.showToast({ detail: error.data ?? error.message?.data ?? error.message ?? error.statusText, life: 5000, severity: "error", summary: 'Oops!' });
        break;
      }
    }

    return Promise.reject(error);
  }
}

export const http = new Http();