<template>
    <nav>
      <Menubar :model="navmenu" class="menubar">
        <template #start>
          <router-link to="/"><img :src="isDark ? './tournament-icon.png' : './tournament-icon-dark.png'" alt="Bracket" /></router-link>
        </template>
        <template #item="{ item, props, root }">
          <a
            v-if="
              (item.requiresAuth === true &&
                user &&
                userStore.isInRole(item.requiredRole)) ||
              item.requiresAuth === undefined ||
              item.requiresAuth === false
            "
            class="flex items-center"
            :class="{ 'nav-menu-link-dark': !isDark, 'nav-menu-link-white': isDark }"
            v-bind="props.action"
          >
            <span class="ml-2" v-if="item.icon" :class="item.icon" />
            <span >{{ item.label }}</span>
            <i v-if="item.items?.some((c: any) => c.showInMenu)"
              :class="['pi pi-angle-down',{ 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },]" >
            </i>
          </a>
        </template>
        <template #end>
          <div class="flex items-center gap-2">
            <ModeSelector></ModeSelector>
            <div v-if="user" class="">
            <Button
              v-if="settingStore.allowNotifications"
              type="button"
              class="me-2"
              icon="pi pi-envelope"
              rounded
              outlined
              :badge="`${notificationStore.myNotifications.length}`"
              @click="loadNotifications(true)"
            />
            <Avatar
              id="avatar"
              :image="userImage()"
              :label="userLabel()"
              size="large"
              :class="avatarBackground"
              shape="circle"
              @click="toggleLogoutMenu"
            />
            <Menu
              id="logoutMenu"
              ref="logoutMenu"
              :model="logoutMenuOptions"
              :popup="true"
            />
            </div>
          </div>
        </template>
      </Menubar>
    </nav>
</template>

<script lang="ts" setup>
import Menu from "primevue/menu";
import { useGtag } from "vue-gtag-next";

import Avatar from 'primevue/avatar';
import Image from "primevue/image";
import Menubar from "primevue/menubar";
import { useDark } from "@vueuse/core";
import { computed, onMounted, ref } from "vue";
import { Notification } from '@/models/notification';

import { useUserStore } from '@/stores/userStore';
import { useNotificationStore } from '@/stores/notificationStore';
import { useSettingStore } from '@/stores/settingStore';
import {
  type UserCredential,
  getRedirectResult,
  getIdTokenResult,
  type IdTokenResult,
} from 'firebase/auth'
import { getCurrentUser, useCurrentUser, useFirebaseAuth } from 'vuefire'

const isDark = useDark();
const notificationStore = useNotificationStore();
const settingStore = useSettingStore();
import ModeSelector from '@/components/User/ModeSelector.vue';
import { RouterView, useRouter } from "vue-router";

const { exception } = useGtag();

const userStore = useUserStore();
const router = useRouter();
const icon = () => isDark.value ? "./tournament-icon-dark.png" : "./tournament-icon.png";
const avatarBackground = computed(() => isDark.value ? "bg-secondary-subtle text-black" : "bg-secondary text-white")

const loadNotifications = async (unread: boolean | null) => {
  await notificationStore.loadMyNotifications(unread);
}
const navmenu = ref([
  {
    label: 'Home',
    command: () => router.push('/')
  },
  {
    label: 'Admin',
    requiresAuth: true,
    requiredRole: 'admin',
    items: [{
      label: 'League Types',
      command: () => router.push('/admin/leagueTypes')
    },{
      label: 'Notifications',
      command: () => router.push('/admin/notifications')
    },{
      label: 'Settings',
      command: () => router.push('/admin/settings')
    },{
      label: 'Users',
      command: () => router.push('/admin/users')
    }]
}]);

const logoutMenu = ref();
const logoutMenuOptions = ref([
  {
    label: "Logout",
    icon: "pi pi-power-off",
    command: () => {
      auth.signOut();
      user.value = null;
    },
  },
]);
const toggleLogoutMenu = (event: Event) => {
  logoutMenu.value.toggle(event);
};

const auth = useFirebaseAuth()!
const user = useCurrentUser();
// display errors if any
const ready = ref(false);

const userImage = (): string => user.value?.photoURL ?? "";
const userLabel = (): string => user.value?.photoURL ? "" : user.value!.email!.charAt(0).toUpperCase();


const clearMessage = async (notification: Notification) => {
  await notificationStore.markRead(notification.id);
}

// only on client side
onMounted(async () => {
  const currentUser = await getCurrentUser();
  if(currentUser){
    await getIdTokenResult(currentUser)
      .then((idTokenResult: IdTokenResult) => {
        const availableRoles = idTokenResult.claims["roles"] as string;
        userStore.setUserRoles(availableRoles?.split(",") ?? []);
      })
      .catch((error) => {
        console.error('failed getIdTokenResult',error);
        exception({ 'description': error, 'fatal': true });
      });
  }
  ready.value = true;
})
</script>