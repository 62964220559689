<template>
  <Dialog modal :visible="ready && !user" :closable="false" :show-header="false">
    <UserLogin></UserLogin>
  </Dialog>
  <AppHeader></AppHeader>
  <div class="">
    <section class="py-3">
      <Suspense>
        <div>
          <RouterView v-if="ready" />
        </div>
        <template #fallback>
          <Card>
            <template #content>
              <Skeleton width="100%" height="5rem"></Skeleton>
            </template>
          </Card>
        </template>
      </Suspense>
    </section>
    <footer>
      <Card>
        <template #content>
          <div class="text-center">&copy; Bracket Champion. All rights reserved.</div>
        </template>
      </Card>
    </footer>
    <Toast />
    <Sidebar
      v-model:visible="sidebar"
      position="right"
      v-if="settingStore.allowNotifications"
    >
      <template #header>
        <h3>Notifications</h3>
      </template>
      <Button @click="loadNotifications(null)" label="Show All" link></Button>
      <Message
        v-for="message in notificationStore.myNotifications"
        :key="message.id"
        :severity="messageSeverity(message)"
        :closable="false"
        :icon="messageIcon(message)"
        @close="clearMessage(message)"
      >
        <div class="d-flex justify-between">
          <div>{{ message.message }}</div>
          <div>Mark Read</div>
        </div>
      </Message>
    </Sidebar>
  </div>
</template>

<script setup lang="ts">
import { useLeagueTypeStore } from "@/stores/leagueTypeStore";
import { ref, watch, computed, onMounted, nextTick } from "vue";
import UserLogin from "./components/User/UserLogin.vue";
import Skeleton from "primevue/skeleton";
import Sidebar from "primevue/sidebar";
import Card from "primevue/card";
import { RouterView } from "vue-router";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Message from "primevue/message";

import { Notification } from "@/models/notification";
import { useNotificationStore } from "@/stores/notificationStore";
import { useSettingStore } from "@/stores/settingStore";
import { useCurrentUser } from "vuefire";

import "firebaseui/dist/firebaseui.css";
import AppHeader from "./components/AppHeader.vue";

const leagueTypeStore = useLeagueTypeStore();
const notificationStore = useNotificationStore();
const settingStore = useSettingStore();

const messageSeverity = (message: Notification) =>
  message.severity == "" ? undefined : message.severity;
const messageIcon = (message: Notification) =>
  message.iconClass == "" ? undefined : message.iconClass;
const ready = ref(false);
const user = useCurrentUser();
const sidebar = ref(false);

const loadNotifications = async (unread: boolean | null) => {
  await notificationStore.loadMyNotifications(unread);
  sidebar.value = true;
};

const clearMessage = async (notification: Notification) => {
  await notificationStore.markRead(notification.id);
};
// only on client side
onMounted(async () => {
  if (user.value) {
    await notificationStore.loadMyNotifications(true);
  }
  await leagueTypeStore.load();
  await settingStore.load();
  ready.value = true;
});
</script>

<style>
li.p-menuitem:has(> .p-menuitem-content) {
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.p-sidebar-header-content {
  width: 100%;
}
</style>

<style scoped>
.p-avatar-lg {
  width: 3rem !important;
  height: 3rem !important;
  font-size: 1.5rem !important;
}

.nav-menu-link-white {
  color: white !important;
}
.nav-menu-link-dark {
  color: black !important;
}
</style>
