<script setup>
import { useDark, useToggle } from "@vueuse/core";

const isDarkTheme = useDark({
  valueDark: "app-dark",
  onChanged(dark) {
    if (
      (dark && !document.documentElement.classList.contains("app-dark")) ||
      (!dark && document.documentElement.classList.contains("app-dark"))
    ) {
      document.documentElement.classList.toggle("app-dark");
    }
  },
});
const toggleDark = useToggle(isDarkTheme);
</script>

<template>
  <Button
    type="button"
    @click="toggleDark()"
    text
    rounded
    :aria-label="isDarkTheme ? 'Dark Mode' : 'Light Mode'"
    :icon="isDarkTheme ? 'pi pi-moon' : 'pi pi-sun'"
  />
</template>
