import type ModelBase from "./modelBase";
export default class LeagueType implements ModelBase {
    id: number;
    name: string;
    sport: string;
    association: string;
    groups: string;
    numGroups: number;
    numTeams: number;
    numRounds: number;
    currentRound: number;
    isActive: boolean;
    year: number;
    lastModifiedUser: string;
    lastModifiedDate: Date;

    constructor(leagueType: LeagueType) {
        this.id= leagueType.id;
        this.name = leagueType.name;
        this.sport = leagueType.sport;
        this.association = leagueType.association;
        this.groups = leagueType.groups;
        this.numGroups = leagueType.numGroups;
        this.numTeams = leagueType.numTeams;
        this.numRounds = leagueType.numRounds;
        this.currentRound = leagueType.currentRound;
        this.isActive = leagueType.isActive;
        this.year = leagueType.year;
        this.lastModifiedUser = leagueType.lastModifiedUser;
        this.lastModifiedDate = leagueType.lastModifiedDate;
    }
 
    get groupsArray():string[] {
        return this.groups.split(',');
    }
    get roundArray(): number[] {
        const range: number[] = [];
        for (let i = 1; i <= this.numRounds; i++) {
            range.push(i);
        }
        return range;
    }
    get numTeamsPerGroup():number {
        return this.numTeams / this.numGroups;
    } 
    get numRoundsPerGroup():number {
        return Math.ceil(Math.log2(this.numTeamsPerGroup));
    } 
    get roundName():string [] {
        const roundName: string[] = []
        for (let index = 0; index < this.numRounds; index++) {
            const teamsleft = this.numTeams/ 2 ** index;
            switch (teamsleft){
                case 2:
                    roundName.push('Final')
                    break
                case 4:
                    roundName.push('Final Four')
                    break
                case 8:
                    roundName.push("Elite Eight")
                    break
                case 16:
                    roundName.push("Sweet Sixteen")
                    break
                default:
                    roundName.push(`Round ${index + 1}`)
                    break
            }
        }
        return roundName
    }
    numTeamsByRound (currentRound: number) {
        return Math.max(1, this.numTeams / Math.pow(2, currentRound-1));
    }
    numPicksByRoundAndGroup (round: number, group: string) {
        if(group === 'Finals')
            return 1;
        const t = Math.max(1, this.numTeams / this.numGroups / Math.pow(2, round))
        return t;
    }
    numMatchupsByRound (currentRound: number) {
        return Math.max(1, this.numTeams / this.numGroups / Math.pow(2, currentRound));
    }
}