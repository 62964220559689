<template>
  <div
    class="bg-surface-50 dark:bg-surface-950 flex items-center justify-center min-h-screen min-w-[100vw] overflow-hidden"
  >
    <div class="flex flex-col items-center justify-center">
      <div
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color) 10%,
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="w-full bg-surface-0 dark:bg-surface-900 py-20 px-8 sm:px-20"
          style="border-radius: 53px"
        >
          <div class="text-center mb-8">
            <div class="text-surface-900 dark:text-surface-0 text-3xl font-medium mb-4">
              Welcome to Bracket Champion!
            </div>
          </div>

          <div v-if="view === 'loginWithEmail'" class="flex flex-col">
            <span class="justify-center text-muted-color font-medium text-center mb-8"
              >Sign in to continue</span
            >
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <InputText
                v-model="loginModel.userEmail"
                placeholder="Email"
                class="w-full md:w-[30rem]"
              ></InputText>
              <p
                v-for="error of y$.userEmail.$errors"
                :key="error.$uid"
                class="w-full md:w-[30rem] text-red-500"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <Password
                class="w-full md:w-[30rem]"
                v-model="loginModel.userPassword"
                placeholder="Password"
                fluid
                :feedback="false"
              ></Password>
              <p
                v-for="error of y$.userPassword.$errors"
                :key="error.$uid"
                class="w-full md:w-[30rem] mb-4 text-red-500"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="flex items-center justify-end mb-4 gap-8">
              <Button
                @click="goResetPassword"
                link
                label="Reset Password"
                class="font-medium no-underline ml-2 text-right cursor-pointer text-primary"
              ></Button>
            </div>
            <Button @click="signinEmailPassword" label="Sign In" class="w-full"></Button>
            <hr />
            <div class="row">
              <div class="col">
                <div class="flex flex-col flex-1 justify-center">
                  <Button
                    @click="goRegister"
                    link
                    label="Need to create an account?"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="view === 'passwordReset'" class="flex flex-col">
            <span
              class="justify-center text-muted-color font-medium text-center mb-4 gap-8"
              >Reset Password</span
            >
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <InputText
                v-model="loginModel.userEmail"
                placeholder="Email"
                class="w-full md:w-[30rem]"
              ></InputText>
              <p
                v-for="error of z$.userEmail.$errors"
                :key="error.$uid"
                class="w-full md:w-[30rem] mb-4 text-red-500"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <Button
                @click="resetPassword"
                label="Reset Password"
                class="w-full md:w-[30rem]"
              ></Button>
            </div>
            <hr />
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <Button
                @click="goLogin"
                label="Cancel"
                severity="danger"
                class="w-full md:w-[30rem]"
              >
              </Button>
            </div>
          </div>
          <div v-else-if="view === 'register'" class="flex flex-col">
            <span
              class="justify-center text-muted-color font-medium text-center mb-4 gap-8"
              >Create an Account</span
            >
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <InputText
                v-model="userModel.displayName"
                class="w-full md:w-[30rem]"
                placeholder="What should we call you?"
              ></InputText>
              <p
                v-for="error of v$.displayName.$errors"
                :key="error.$uid"
                class="w-full md:w-[30rem] mb-4 text-red-500"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <InputText
                v-model="userModel.userEmail"
                placeholder="Email"
                class="w-full md:w-[30rem]"
              ></InputText>
              <p
                v-for="error of v$.userEmail.$errors"
                :key="error.$uid"
                class="w-full md:w-[30rem] mb-4 text-red-500"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <Password
                class="w-full md:w-[30rem]"
                v-model="userModel.userPassword"
                placeholder="Password"
              ></Password>
              <p
                v-for="error of v$.userPassword.$errors"
                :key="error.$uid"
                class="w-full md:w-[30rem] mb-4 text-red-500"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <Password
                class="w-full md:w-[30rem]"
                v-model="userModel.confirmPassword"
                placeholder="Confirm Password"
              ></Password>
              <p
                v-for="error of v$.confirmPassword.$errors"
                :key="error.$uid"
                class="w-full md:w-[30rem] mb-4 text-red-500"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <Button
                @click="register"
                label="Create Account"
                class="w-full md:w-[30rem]"
              ></Button>
            </div>
            <hr />
            <div class="flex flex-col items-center justify-between mb-4 gap-2">
              <Button
                link
                @click="goLogin"
                label="Use an Existing Account"
                class="w-full md:w-[30rem]"
              >
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toaster } from '@/services/toaster';
import { computed, reactive, ref } from 'vue';
import Button from 'primevue/button';
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { useFirebaseAuth } from 'vuefire'
import useVuelidate from '@vuelidate/core';
import { required, sameAs, email, minLength, helpers } from '@vuelidate/validators';
import { useGtag } from 'vue-gtag-next';
import { useUserStore } from '@/stores/userStore';
import type User from '@/models/user';

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  type UserCredential,
} from 'firebase/auth'

const toast = toaster();
const userStore = useUserStore();
const { event, exception  } = useGtag();
const userModel = reactive({
  displayName: '',
  userEmail: '',
  userPassword: '',
  confirmPassword: '',
})
const loginModel = reactive({
  userEmail: '',
  userPassword: '',
})
const view = ref<'loginWithEmail' | 'register' | 'passwordReset'>('loginWithEmail')

const auth = useFirebaseAuth()!
const registerRules = computed(() => ({
  displayName: {
    required: helpers.withMessage('Display name is required', required),
  },
  userEmail: {
    required: helpers.withMessage('Email is required',required),
    email: helpers.withMessage('Please use a valid email address',email)
  },
  userPassword: {
    required: helpers.withMessage('Password is required',required),
    minLength: helpers.withMessage('Please use a password of at least 8 characters',minLength(8))
  },
  confirmPassword: {
    required: helpers.withMessage('Password confirmation is required', required),
    sameAsPassword: helpers.withMessage('Password values do not match',sameAs(userModel.userPassword))
  }
}))
const loginRules = computed(() => ({
  userEmail: {
    required: helpers.withMessage('Email is required',required),
    email: helpers.withMessage('Please use a valid email address',email)
  },
  userPassword: {
    required: helpers.withMessage('Password is required',required),
  }
}))
const resetPasswordRules = computed(() => ({
  userEmail: {
    required: helpers.withMessage('Email is required',required),
    email: helpers.withMessage('Please use a valid email address',email)
  }
}))
const v$ = useVuelidate(registerRules, userModel)
const y$ = useVuelidate(loginRules, loginModel);
const z$ = useVuelidate(resetPasswordRules, loginModel);

const goRegister = () => {
  view.value = 'register';
  userModel.userEmail = '';
  userModel.userPassword = '';
}
const goLogin = () => {
  view.value = 'loginWithEmail';
  userModel.userEmail = '';
  userModel.userPassword = '';
}
const register = async () => {
  if(await v$.value.$validate()){
      createUserWithEmailAndPassword(auth, userModel.userEmail, userModel.userPassword)
      .then(async (userCredential: UserCredential) => {
        updateProfile(userCredential.user, { displayName: userModel.displayName });
        const user = {
          displayName: userModel.displayName,
          email: userCredential.user.email,
          photoUrl: userCredential.user.photoURL ?? '',
          uid: userCredential.user.uid,
          creationTime: userCredential.user.metadata.creationTime == null ? new Date() : new Date(userCredential.user.metadata.creationTime),
          lastSignInTime: userCredential.user.metadata.lastSignInTime == null ? new Date() : new Date(userCredential.user.metadata.lastSignInTime)
        } as User;
        await userStore.saveUser(user);
        event('sign_up', { method: 'Email' })
      })
      .catch(reason => {
        exception({ description: reason.message, fatal: true });
        event('exception', { description: reason.message, fatal: true })
        toast.add({severity: 'error', summary: 'Create account failed!', detail: reason.message, life: 3000})
        console.error('Failed createUserWithEmailAndPassword', reason)
      });
  }
  else {
    toast.add({ summary: "Oops", severity: "error", detail: "Please correct the form and try again", life: 3000})
  }
}
const signinEmailPassword = async () => {
  if(await y$.value.$validate()){
    signInWithEmailAndPassword(auth, loginModel.userEmail, loginModel.userPassword)
    .then(async (userCredential: UserCredential) => {
        const user = {
          displayName: userCredential.user.displayName,
          email: userCredential.user.email,
          photoUrl: userCredential.user.photoURL ?? '',
          uid: userCredential.user.uid,
          creationTime: userCredential.user.metadata.creationTime == null ? new Date() : new Date(userCredential.user.metadata.creationTime),
          lastSignInTime: userCredential.user.metadata.lastSignInTime == null ? new Date() : new Date(userCredential.user.metadata.lastSignInTime)
        } as User;
        await userStore.saveUser(user);
        event('sign_up', { method: 'Email' })
      })
    .catch((reason) => {
      toast.add({severity: 'error', summary: 'Login failed!', detail: `Login failed.  ${reason.message}`, life: 3000})
      console.error('Failed signinEmailPassword', reason)
      loginModel.userPassword = '';
      exception({description: reason.message, fatal: true});

    });
    event('login', { method: 'Email' })
  }
  else {
    toast.add({severity: 'error', summary: 'Login failed!', detail: 'Please check your credentials and try again.', life: 3000})
    loginModel.userPassword = '';
  }
}
const goResetPassword = () => view.value = 'passwordReset';
const resetPassword = async () => {
  if(await z$.value.$validate()){
    sendPasswordResetEmail(auth, loginModel.userEmail)
    .then(() => {
      toast.add({ summary: 'Ok!', detail: `Please check your email for a link to reset your password.`, severity: 'success', life: 5000 });
    }).catch((error) => {
      toast.add({ summary: 'Oops!', detail: `Please check the data you entered is correct. ${error.message}`, severity: 'error' });
      exception({description: error.message, fatal: true});
    })
  }
}
</script>

<style scoped>
.google {
  background-color: #ffffff;
  color: #757575;
}
</style>
<style>
.p-password-input {
  width: 100%;
}
</style>
